import React, { FC, memo, useCallback, ReactNode, useState, FocusEvent } from 'react';
import classNames from 'classnames';
import Mouseover from 'components/Mouseover';

export interface IListItemProps {
  value?: any,
  active?: boolean,
  label?: string,
  onClick?: (value?: any) => void,
  children: ReactNode,
  mouseover?: string
};

const ListItem: FC<IListItemProps> = ({ label, value, active = false, onClick, children, mouseover }) => {
  const [focus, setFocus] = useState(false);
  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (event.target.classList.contains('focus-visible')){
      setFocus(true);
    }
  }, [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);
  
  const [opened, setOpened] = useState(false);
  const handleChange = useCallback(() => {
    if (onClick && !children) {
      onClick(value);
    } else {
      setOpened((opened) => active || !opened);
    }
  }, [onClick, value, children, active]);

  const itemClasses = classNames({
    'list-item': true,
    'active': active,
    'list-item-haschildren': children,
    'focus-visible': focus,
  });

  return <>
    <Mouseover content={mouseover}>
      <label className={itemClasses}>
        <span className="list-item-label">
          {label}
        </span>
        <input onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} checked={active} type="checkbox" />
      </label>
    </Mouseover>
    {children && (
      <div className={classNames({ 'list-item-children': true, 'open': active || opened })}>
        {children}
      </div>
    )}
  </>
};

export default memo(ListItem);
