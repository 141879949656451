import { useEffect, useCallback } from 'react';
import api from 'services/api';
import { dispatch, useSelector } from 'reducers';
import { FETCH_OBSERVATIONS_PENDING, FETCH_OBSERVATIONS_FULFILLED, FETCH_OBSERVATIONS_REJECTED } from 'reducers/observation';
import HttpError from '../HttpError';
import { useRouting } from 'services/routing';

export interface IObservation {
  id: string,
  name?: string,
  selected?: boolean,
  sub?: IObservation[],
  completeService?: boolean,
};

const WATCH_STOPPED = 'WATCH_STOPPED';

export function findObservationById(observations: IObservation[], id: string): IObservation | undefined {
  for (const obs of observations) {
    if (obs.id.includes(id)) {
      return obs;
    } else if (obs.sub) {
      const found = findObservationById(obs.sub, id)
      if (found) {
        return found
      }
    }
  }
}

export function mapObservations(observations: IObservation[], selected: string[] = []): IObservation[] {
  return observations.map((obs) => ({
    ...obs,
    selected: !!selected.find((s) => s.includes(obs.id)) || 
      !!obs.sub?.find((su) => selected.find((s) => s.includes(su.id))),
    sub: obs.sub && mapObservations(obs.sub, selected),
  }))
}

export function selectedObservations(observations: IObservation[], selected: string[] = []): IObservation[] {
  return mapObservations(observations, selected).filter((obs) => {
    return obs.selected;
  })
}

export function useObservations() {
  const { params, setParams } = useRouting();
  const observations = useSelector((state) => state.observations);
  const loading = useSelector((state) => state.loadingObservations);
  const error = useSelector((state) => state.observationsError);
  

  useEffect(() => {
    list(params.id);
  }, [params.id]);

  const toggleObservation = useCallback((id: string) => {
    const obs = findObservationById(observations, id);
    if (obs && obs.id) {
      let observations = params.observations || [];
      if (observations.find(o => obs.id.includes(o) || o.includes(obs.id))) {
        if (!observations.includes(obs.id)) {
          observations = observations.filter((id) => !id.includes(obs.id) && !obs.id.includes(id));
          observations = [obs.id, ...observations]; 
        } else {
          observations = observations.filter((id) => !id.includes(obs.id) && !obs.id.includes(id));
        }
      } 
      else if (!observations.includes(obs.id)) {
        if (obs.id.includes(WATCH_STOPPED) && observations.find(o => o.includes(WATCH_STOPPED))) {
          observations = observations.filter((id) => !id.includes(WATCH_STOPPED));
        }
        observations = [obs.id, ...observations];
      }
      setParams({ observations });
    }
  }, [observations, setParams, params.observations]);

  return { 
    observations: mapObservations(observations, params.observations),
    selected: selectedObservations(observations, params.observations),
    loading, 
    error,
    toggleObservation,
  };
}

export function list(productId?: string) {
  dispatch({ type : FETCH_OBSERVATIONS_PENDING });
  return api.get(`/observations?productId=${productId}`).then((observations: IObservation[]) => {
    if (observations) {
      dispatch({ 
        type: FETCH_OBSERVATIONS_FULFILLED, 
        payload: observations,
      });
      return observations;
    } else {
      throw new HttpError({ status: 500 });
    }
  }).catch((error) => {
    dispatch({ type: FETCH_OBSERVATIONS_REJECTED });
    throw error;
  })
}
